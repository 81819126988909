<template>
  <div class="pa-5">
    <v-card class="mx-auto">
      <v-card-title>
        <h2 v-t="'Categories.ProductCategoriesManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$router.push('Categories/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('Categories.AddCategory') }}
        </v-btn>
      </v-card-title>
      <div class="table-container">
        <div class="header-row">
          <div class="little-space-teehee"></div>
          <div class="table-element drag"></div>
          <div v-t="'General.PublishingStatus'" class="table-element"></div>
          <div v-t="'Categories.CategoryName'" class="table-element"></div>
          <div v-t="'General.Actions'" class="table-element"></div>
        </div>
        <vuetify-draggable-treeview v-model="categories" @input="updateIndexes" style="display: flex; flex-flow: column nowrap; justify-content:space-evenly;">
          <template v-slot:label="{ item }">
            <div class="body-row">
              <div class="table-element drag">
                <div class="table-element-center">
                  <v-icon style="padding: 10px; cursor: move">mdi-cursor-move</v-icon>
                </div>
              </div>
              <div class="table-element">
                <div class="table-element-center">
                  <v-switch
                  @change="togglePublished(item)"
                    v-model="item.published"
                  ></v-switch>
                </div>
              </div>
              <div class="table-element">
                  {{ item.name }}
              </div>
              <div class="table-element">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="secondary" icon dark @click="$router.push('Categories/' + item.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'General.Edit'"></span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" icon @click="openDeleteModal(item)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'General.Delete'"></span>
                </v-tooltip>
              </div>
            </div>
          </template>
        </vuetify-draggable-treeview>
      </div>
      </v-card>
      <DeleteModal ref="deleteModal" @confirm="deleteCategory" />
  </div>
</template>

<script>
import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'
import DeleteModal from '@/components/DeleteModal.vue'

export default {
  name: 'Categories',
  components: {
    VuetifyDraggableTreeview,
    DeleteModal
  },
  data () {
    return {
      categories: [],
      products: [],
      itemToDelete: null
    }
  },
  created () {
    this.getCategories()
  },
  methods: {
    initCategories () {
      this.categories.sort((a, b) => {
        return b.index < a.index ? 1 : -1
      })
    },
    async updateIndexes (categories) {
      return new Promise((resolve, reject) => {
        categories.forEach(async (category) => {
          if (category.index !== categories.indexOf(category)) {
            category.index = categories.indexOf(category)
            await this.updateCategory(category)
          }
          if (category.index === categories.length - 1) {
            resolve()
          }
        })
      })
    },
    async updateCategory (category) {
      await this.$store.dispatch('editCategory', category).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    openDeleteModal (item) {
      this.itemToDelete = item
      this.$refs.deleteModal.openModal()
    },
    deleteCategory () {
      if (!this.itemToDelete) {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.NoItemSelectedToDelete')
        })
        return
      }

      this.$store.dispatch('getAllProducts').then(() => {
        const products = this.$store.getters['GET_PRODUCTS']()
        const isCategoryInUse = products.some(product => product.categoryId === this.itemToDelete.id)

        if (isCategoryInUse) {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.ErrorDeleteCategory')
          })
        } else {
          this.$store.dispatch('deleteCategory', this.itemToDelete.id)
            .then(async () => {
              const index = this.categories.indexOf(this.itemToDelete)
              if (index > -1) this.categories.splice(index, 1)

              await this.updateIndexes(this.categories)
              this.getCategories()

              this.$emit('notify', {
                color: 'green',
                text: this.$t('Messages.CategoryDeleted')
              })
            })
            .catch(err => {
              this.$emit('notify', {
                color: 'red',
                text: this.$t('Messages.' + err.response.data)
              })
            })
            .finally(() => {
              this.$refs.deleteModal.closeModal()
              this.itemToDelete = null
            })
        }
      })
    },
    getCategories () {
      this.$store.dispatch('getAllCategories').then(() => {
        this.categories = this.$store.getters['GET_CATEGORIES']()
        this.initCategories()
      })
    },
    togglePublished (category) {
      this.$store.dispatch('editCategory', category).then(res => {
        this.getCategories()
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    }
  }
}
</script>

<style scoped>
.table-container {
  display: flex;
  flex-flow: column nowrap;
}

.header-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 48px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.header-row:hover,

.header-row .table-element {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}

.little-space-teehee {
  width: 24px;
}

.table-element {
  flex: 1;
  flex-basis: 0;
  min-width: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  white-space: normal;
}

.drag {
  flex-grow: 0.2;
}

.table-element-center {
  display: flex;
  justify-content: center;
}

.body-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
</style>
